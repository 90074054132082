<template>
  <v-card
    outlined
    class="accent"
    :loading="loadingAcesso"
    :disabled="loadingAcesso"
  >
    <v-card-title
      class="px-4 pt-4 pb-0 mb-3 text-h6 primary--text font-weight-bold"
    >
      {{ user_id ? "Atualizar Acesso" : "Criar Acesso" }}
    </v-card-title>
    <v-card-text class="px-4 pb-4 pt-0">
      <v-form ref="loginForm" v-model="validForm" lazy-validation>
        <v-row align="center">
          <!-- Login -->
          <v-col cols="12" md="">
            <v-text-field
              label="Login"
              v-model="acesso.email"
              :disabled="user_id ? true : false"
              dense
              hide-details
              required
              :rules="[formRules]"
              prepend-inner-icon="mdi-account"
            >
            </v-text-field>
          </v-col>

          <!-- Password -->
          <v-col cols="12" md="">
            <v-text-field
              :label="user_id ? 'Nova Senha' : 'Senha'"
              v-model="acesso.password"
              :type="show ? 'text' : 'password'"
              dense
              hide-details
              prepend-inner-icon="mdi-lock"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
            >
            </v-text-field>
          </v-col>

          <!-- Password Confirmation -->
          <v-col cols="12" md="" v-if="user_id">
            <v-text-field
              label="Confirmar Nova Senha"
              v-model="acesso.password_confirmation"
              :type="show ? 'text' : 'password'"
              dense
              hide-details
              :required="acesso.password ? true : false"
              :rules="acesso.password ? [formRules] : []"
              prepend-inner-icon="mdi-lock"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="auto">
            <v-btn
              color="primary"
              depressed
              block
              @click="user_id ? updateAcesso() : addAcesso()"
            >
              {{ user_id ? "Atualizar Acesso" : "Criar Acesso" }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { inputRequired, validaEmail } from "@/helpers/utils.js";
import { putUsuarioAcesso, postUsuarioAcesso } from "@/api/gestor/users.js";

export default {
  name: "GerenciadorDeAcessos",

  props: {
    prop_acesso: {
      type: Object,
    },
    user_id: {
      type: [Number, String],
    },
    acesso_para: {
      type: String,
      required: true,
    },
    pai_id: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      acesso: {
        email: "",
        password: "",
        password_confirmation: "",
      },
      validForm: true,
      show: false,
      formRules: inputRequired,
      emailRule: validaEmail,
      loadingAcesso: false,
    };
  },

  methods: {
    addAcesso() {
      if (this.$refs.loginForm.validate()) {
        this.loadingAcesso = true;

        let acesso = {};
        acesso.acesso_para = this.acesso_para;
        acesso.email = this.acesso.email;
        acesso.password = this.acesso.password;

        postUsuarioAcesso(this.pai_id, acesso)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Acesso atualizado com sucesso!");
              this.$emit("update-pai");
              this.loadingAcesso = false;
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              if (error.response.data.email) {
                this.$toast.error(
                  "Este login ja existe, por favor escolha outro!"
                );
              }
            }
            this.loadingAcesso = false;
          });
      }
    },
    updateAcesso() {
      if (this.$refs.loginForm.validate()) {
        this.loadingAcesso = true;

        let acesso = {};
        acesso.id = this.acesso.id;
        acesso.password = this.acesso.password;
        acesso.password_confirmation = this.acesso.password_confirmation;

        putUsuarioAcesso(acesso.id, acesso)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Acesso atualizado com sucesso!");
              this.loadingAcesso = false;
            }
          })
          .catch(() => {
            this.loadingAcesso = false;
          });
      }
    },
  },

  mounted() {
    if (this.prop_acesso) {
      this.acesso.id = this.prop_acesso.id;
      this.acesso.email = this.prop_acesso.email;
    }
  },
};
</script>

<style lang="scss"></style>
