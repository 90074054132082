import api from "../axios_service.js";

export function putUsuarioAcesso(id, usuario) {
  let body = {};
  for (let key in usuario) {
    body[key] = usuario[key];
  }
  return api.put(`/gestor/usuarios-acesso/${id}`, body);
}

export function postUsuarioAcesso(pai_id, usuario) {
  let body = {};
  for (let key in usuario) {
    body[key] = usuario[key];
  }
  return api.post(`/gestor/usuarios-acesso/${pai_id}/add`, body);
}
